<template>
    <div class="w-60">
        <div class="w-full h-32 relative border mb-2 rounded flex justify-center p-2.5 items-center">
            <div class="absolute w-8 h-8 left-0 top-0 cursor-pointer flex border rounded justify-center items-center">
                <Icon name="TrashIcon" size="4" color="gray-500" />
            </div>
            <img :src="school.image" class="h-full">
        </div>
        <Button :label="school.removeButtonLabel" iconName="MinusCircleIcon" text-color="yellow-500"
                class="w-full bg-white border border-yellow-500 text-xs mb-2" :leading="true" />
        <p class="py-2.5">{{school.name}}</p>
        <div class="w-full border-b" v-for="(list, listIndex) in school.descItems">
            <div v-if="list.title" class="w-full inline-flex items-center mt-2 py-3 px-1">
                <Icon :name="list.icon" size="4" color="gray-900" custom-class="mr-2" />{{list.title}}
            </div>
            <template v-for="(item, itemIndex) in list.items">
                <div class="w-fit flex space-x-4" :class="itemIndex%2 === 0 ? 'bg-gray-100': 'bg-white'">
                    <div class="w-60 py-1 px-1 flex items-center">
                        <Button v-if="school.descItems[listIndex].items[itemIndex].isLocked"
                                label="Locked" iconName="LockClosedIcon" text-color="gray-500" icon-size="3"
                                class="w-fit bg-white border border-gray-500 text-xs px-2 py-1" :leading="true" />
                        <p class="success-row" v-else v-html="school.descItems[listIndex].items[itemIndex].label" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Icon from "../../../atoms/Icons/Icons.vue";
    import Text from "../../../atoms/Text/Text.vue";
    import Button from "../../Button/WithIcon/WithIcon.vue";

    export default {
        name: "DashboardButtons",
        components: {
            Icon,
            Text,
            Button
        },
        props: {
            school: {
                type: Array,
                default: () => []
            },
        },
    };
</script>


<style>
    .w-fit {
        width: fit-content;
    }

    .success-row b {
        color: #29baac !important;
    }
</style>