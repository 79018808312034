<template>
  <div class="px-3.5 pt-5 pb-10 mx-auto w-7xl max-w-7xl">
    <!--    <div class="w-full flex flex-col sm:flex-row justify-between pb-16 border-b">-->
    <!--      <div class="flex-2 py-5">-->
    <!--        <Greeting v-bind="GreetingProps"/>-->
    <!--      </div>-->
    <!--      <div class="flex-1 ml-8">-->
    <!--        <VisionBoard :list="visionList"/>-->
    <!--        &lt;!&ndash; <Calendar v-bind="calendarProps" /> &ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-3 sm:gap-8">
      <div class="col-span-2">
        <Greeting
          v-bind="{
            ...GreetingProps,
            button: {
              ...GreetingProps.button,
              show: !isPaid,
            },
          }"
          @goToSubscription="$router.push('/pricing')"
        />
        <div class="flex flex-col sm:flex-row justify-center items-center sm:justify-start sm:items-start gap-4 mt-4 lg:ml-0 sm:ml-36">
          <div class="h-32 w-32 flex-none hidden lg:block" />
          <div>
            Don’t forget to watch our
            <span class="text-primary font-bold cursor-pointer" @click="$emit('changeTab', 3)">Courses</span>
            to teach you everything there is to know about financial aid.
          </div>
        </div>
        <!-- <div class="bg-primary px-5 py-2 rounded-md">
          <Text size="sm" weight="" color="white" content="Our Pell Grant is under construction until July 15th to implement the new SAI formula to estimate your awards accurately. Our team at FAID appreciates your patience." />
        </div> -->
      </div>

      <div class="flex-1 sm:ml-36 lg:ml-8 mt-10 sm:mt-0">
        <VisionBoard :list="getVisionList()" />
        <!-- <Calendar v-bind="calendarProps" /> -->
      </div>
    </div>
    <div class="w-full flex" v-if="!state.currentFinApp">
      <div class="m-auto" v-if="startApplicationProps">
        <a :href="startApplicationProps.href">
          <Button :content="startApplicationProps.label" class="my-4 px-6 py-3 text-lg" :class="`bg-${themeColor}`" />
        </a>
      </div>
    </div>
    <div class="w-full" v-else>
      <div class="w-full py-10">
        <DashboardButtons v-bind="dashboardButtonsProps" :app="app" />
      </div>
      <div class="w-full pt-32 lg:pt-10 sm:mt-0 flex flex-col space-x-10 sm:flex-row">
        <div class="flex-1 self-center sm:self-start schools-wrapper">
          <Text :content="inSchools.title" size="2xl" color="gray-800" weight="bold" custom-class="text-center sm:text-start" />
          <div class="mt-1 text-center">
            <Text content="Coverage Includes:" color="primary" weight="bold" customClass="relative" />
            <div class="flex flex-wrap gap-x-2 mt-1 justify-center">
              <div class="flex items-center space-x-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Tuition & Fees" />
              </div>
              <div class="flex items-center space-x-2 ml-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Misc. Fees" />
              </div>
              <div class="flex items-center space-x-2 ml-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Room & Board" />
              </div>
            </div>
          </div>
          <div class="w-full flex flex-wrap mt-5 grid grid-cols-1 lg:grid-cols-2 gap-3">
            <div class="school-card-container" v-for="school in inSchools.list">
              <SchoolCard @onAddToList="() => onAddToList(school, 'inState')" v-bind:school="school" :isInList="isInList(school.id)" :getCalculatedData="getCalculatedData" :locked="!isPaid" :selectedSchool="selectedSchool" :isLoading="isLoading" :isInSchool="true" />
            </div>
            <div class="school-card-container" v-if="inSchools.list && inSchools.list.length % 2 !== 0"></div>
          </div>
        </div>
        <div class="flex-1 self-center sm:self-start mt-10 sm:mt-0">
          <Text :content="outSchools.title" size="2xl" color="gray-800" weight="bold" custom-class="text-center sm:text-start" />
          <div class="mt-1 text-center">
            <Text content="Coverage Includes:" color="primary" weight="bold" customClass="relative" />
            <div class="flex flex-wrap gap-x-2 mt-1 justify-center">
              <div class="flex items-center space-x-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Tuition & Fees" />
              </div>
              <div class="flex items-center space-x-2 ml-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Misc. Fees" />
              </div>
              <div class="flex items-center space-x-2 ml-2">
                <div class="w-4 h-4 rounded-full bg-primary flex items-center justify-center">
                  <Icon name="CheckIcon" color="white" isOutline size="3" />
                </div>
                <Text size="sm" color="black" content="Room & Board" />
              </div>
            </div>
          </div>
          <div class="w-full mt-5 grid grid-cols-1 lg:grid-cols-2 gap-3">
            <div class="school-card-container" v-for="school in outSchools.list">
              <SchoolCard @onAddToList="() => onAddToList(school, 'outState')" v-bind:school="school" :isInList="isInList(school.id)" :getCalculatedData="getCalculatedData" :locked="!isPaid" :isLoading="isLoading" :isInSchool="false" />
            </div>
            <div class="school-card-container" v-if="outSchools.list && outSchools.list.length % 2 !== 0"></div>
          </div>
        </div>
      </div>
      <div class="w-full" v-if="showMore">
        <Button variant="primary" :content="moreSchoolLabel" class="px-16 py-4 font-semibold text-md shadow-lg btn-868c97 w-full mt-8" :onClick="this.$emit('showMore')" />
      </div>
    </div>
  </div>
</template>

<script>
import TabsWithTriangleRightBorder from "../../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../../molecules/Greeting/Greeting.vue";
import Calendar from "../../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import VisionBoard from "../../../molecules/VisionBoard/VisionBoard.vue";
import { _ } from "vue-underscore";
import { get } from "lodash";

export default {
  components: {
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    VisionBoard,
  },
  props: {
    schoolList: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Object,
      default: () => {},
    },
    themeColor: {
      type: String,
      default: "primary",
    },
    backBtnLabel: {
      type: String,
      default: "",
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTabId: {
      type: Number,
      default: -1,
    },
    GreetingProps: {
      type: Object,
      default: null,
    },
    calendarProps: {
      type: Object,
      default: null,
    },
    dashboardButtonsProps: {
      type: Object,
      default: null,
    },
    inSchools: {
      type: Object,
      default: null,
    },
    outSchools: {
      type: Object,
      default: null,
    },
    moreSchoolLabel: {
      type: String,
      default: "",
    },
    startApplicationProps: {
      type: Object,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    filterTab: { type: Object },
    getCalculatedData: {
      type: Function,
      default: () => "",
    },
    userVisionList: {
      type: Array,
      default: () => [],
    },
    app: {
      type: Object,
      default: () => {},
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
    updateUserVisionList: {
      type: Function,
      default: () => {},
    },
    changeTab: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    console.log(this.inSchools, this.outSchools);
    return {
      visionList: this.userVisionList,
      get,
      isLoading: false,
      selectedSchool: null,
    };
  },
  watch: {
    userVisionList: function (value) {
      this.visionList = value;
    },
  },
  methods: {
    isInList(schoolId) {
      return this.visionList.findIndex((item) => item.id === schoolId) > -1;
    },
    async onAddToList(school, type) {
      if (this.isPaid || this.isInList(school?.id)) {
        this.selectedSchool = school;
        this.isLoading = true;
        this.updateUserVisionList(school, type);
        this.isLoading = false;
      } else {
        this.$router.push("/pricing");
      }
    },
    getVisionList() {
      return _.first([...this.visionList], 12);
    },
  },
};
</script>

<style>
.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}
@media (max-width: 425px) {
  .schools-wrapper,
  .flip-card {
    width: 100%;
    max-width: 100% !important;
  }
}
</style>
