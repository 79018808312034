
<template>
	<div class="video-wrapper">
		<video v-if="type === 'normal'" :width="width" controls :style="{height: height + 'px !important'}">
  		<source :src="url">
		</video>
		<YoutubeVue3 v-if="type === 'you_tube'" :videoid="youtube_id" :loop="1" :autoplay="0" :width="width" :height="height"/>
		<iframe v-if="type === 'vimeo'" :src="vimeo_id.includes('https') ? vimeo_id: `https://player.vimeo.com/video/${vimeo_id}`" :width="width" :height="height" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
	</div>
</template>

<script>
import { YoutubeVue3 } from 'youtube-vue3'
export default {
	components: {
		YoutubeVue3,
	},
	props: {
		type: {
			type: String,
			default: 'normal'
		},
		url: {
			type: String,
			default: ''
		},
		youtube_id: {
			type: String,
			default: ''
		},
		vimeo_id: {
			type: String,
			default: ''
		},
		width: {
			type: Number,
			default: 640
		},
		height: {
			type: Number,
			default: 360
		}
	}
}
</script>
<style scoped>
	.video-wrapper { max-width: 600px; }
</style>
