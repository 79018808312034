<template>
  <div class="px-3.5 py-5 mx-auto w-7xl max-w-7xl">
    <!-- <Text :content="'Courses'" size="3xl" color="gray-800" weight="bold"/> -->
    <div class="grid grid-cols-1 sm:grid-cols-4 relative">
      <div class="block w-full pl-0 sm:pl-2 pr-0 sm:pr-4">
        <div class="my-5">
          <input
            type="text"
            class="px-4 py-1 border rounded-md w-full"
            placeholder="Search"
            v-model="searchText"
          />
        </div>
        <div class="block">
          <ul
            role="list"
            class="category-item divide-y divide-gray-200 pl-0 list-none"
          >
            <li
              v-for="(item, index) in getFilter(mediaList)"
              :key="index"
              class="py-4 cursor-pointer"
              @click="
                () => {
                  selectedVideo = null;
                  selectedCollection = item;
                }
              "
            >
              <p
                class="text-sm font-semibold"
                :class="
                  selectedCollection?.id === item.id
                    ? 'text-primary'
                    : 'text-gray-900'
                "
              >
                {{ item.name }}
              </p>
              <p class="text-xs text-gray-500">{{ item.description }}</p>
            </li>
          </ul>
          <div
            class="text-sm"
            v-if="mediaCollections && searchText && mediaList.length === 0"
          >
            No result found
          </div>
        </div>
      </div>
      <div class="col-span-3 sm:border-l-2 border-none">
        <div class="p-0 sm:p-4" v-if="selectedVideo && selectedVideo.id">
          <BackButton
            custom-class="mb-5"
            :label="backBtnLabel ? backBtnLabel : 'Back'"
            :onClick="() => (selectedVideo = null)"
          />
          <div class="w-full px-0 sm:px-2 mt-9">
            <div
              class="w-full"
              :class="{ border: !selectedVideo?.member?.source }"
            >
              <Video
                :type="selectedVideo?.member?.socialPlatform?.toLowerCase()"
                :url="selectedVideo.member.source"
                :vimeo_id="selectedVideo.member.source"
                :youtube_id="selectedVideo.member.source"
                :height="400"
                width="100%"
                v-if="selectedVideo?.member?.source"
              />
            </div>
            <div class="mt-4 mx-1 text-sm font-semibold text-gray-900">
              {{ selectedVideo?.member?.name }}
            </div>
            <div class="mx-1 text-xs text-gray-500 mt-1">
              {{ selectedVideo?.member?.description }}
            </div>
          </div>
        </div>
        <div v-else>
          <div
            role="list"
            class="
              w-full
              grid grid-cols-1
              gap-0
              sm:gap-6
              sm:grid-cols-2
              md:grid-cols-3
              lg:grid-cols-3
            "
            v-if="selectedCollection?.members?.length > 0"
          >
            <div
              v-for="(item, index) in selectedCollection.members"
              :key="index"
              @click="() => (selectedVideo = item)"
              class="
                cursor-pointer
                divide-y divide-gray-200
                rounded-lg
                bg-white
              "
            >
              <div class="flex flex-1 flex-col p-4 sm:p-6 mx-auto">
                <div
                  class="
                    h-32
                    w-full
                    flex-shrink-0
                    bg-gray-100
                    flex
                    justify-center
                    items-center
                    relative
                  "
                >
                  <img
                    class="w-full h-full object-contain px-2 py-2"
                    :src="'https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png'"
                    alt=""
                  />
                  <Icons
                    v-if="!item?.member?.avatar"
                    name="PlayIcon"
                    color="gray-600"
                    size="10"
                    custom-class="absolute"
                  />
                </div>
                <h3 class="mt-4 text-sm font-semibold text-gray-900">
                  {{ item?.member?.name }}
                </h3>
                <dl class="mt-1 flex flex-grow flex-col justify-between">
                  <dt class="sr-only">Title</dt>
                  <dd class="text-xs text-gray-500">
                    {{ item?.member?.description }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isPaid"
        class="absolute inset-0 flex justify-center items-center"
        style="background-color: #ffffff80"
      >
        <Icons name="LockClosedIcon" isOutline size="16" color="black" />
      </div>
    </div>
    <!--        <Header v-bind="header"/>-->
    <!--        <div class="w-full border-b-0 sm:border-b">-->
    <!--            <div class="px-3.5 mx-auto w-7xl max-w-7xl">-->
    <!--                <BackButton custom-class="mt-5 mb-5" :label="backBtnLabel" />-->
    <!--                <TabsWithTriangleRightBorder :tabs="tabs" :selected-tab-id="2" />-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="px-3.5 py-5 mx-auto w-7xl max-w-7xl">-->

    <!--        </div>-->
    <!--        <Footer v-bind="footer"/>-->
  </div>
</template>

<script>
import Header from "../../../organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue";
import Footer from "../../../organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue";
import BackButton from "../../../molecules/Button/BackButton/BackButton.vue";
import TabsWithTriangleRightBorder from "../../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../../molecules/Greeting/Greeting.vue";
import Calendar from "../../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import TabsInPills from "../../../molecules/Tabs/TabsInPills/TabsInPills.vue";
import Description from "../../../organisms/TermsAndPolicy/ContentItem/Item.vue";
import Video from "../../../atoms/Video/Video.vue";
import Icons from "../../../atoms/Icons/Icons";
import { values } from "lodash";

export default {
  components: {
    Header,
    Footer,
    BackButton,
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    TabsInPills,
    Description,
    Video,
    Icons,
  },
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Object,
      default: () => {},
    },
    themeColor: {
      type: String,
      default: "primary",
    },
    backBtnLabel: {
      type: String,
      default: "",
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    selectedTabId: {
      type: Number,
      default: -1,
    },
    mediaCollections: {
      type: Object,
      default: null,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCollection: this.mediaCollections
        ? values(this.mediaCollections)?.[0]
        : null,
      searchText: "",
      mediaList: this.mediaCollections ? values(this.mediaCollections) : [],
      selectedVideo: null,
    };
  },
  methods: {
    getFilter(items) {
      if (this.searchText) {
        return items?.filter((i) => {
          const isVideoTitleMatch = i.members
            ? i.members.find((member) => {
                return (
                  member?.member &&
                  member?.member?.name
                    ?.toLowerCase()
                    ?.includes(this.searchText?.toLowerCase())
                );
              })
            : null;
          return (
            i.name?.toLowerCase()?.includes(this.searchText?.toLowerCase()) ||
            isVideoTitleMatch
          );
        });
      } else {
        return items;
      }
    },
  },
  watch: {
    searchText: function (value) {
      if (value.trim()) {
        this.mediaList = values(this.mediaCollections).filter((item) => {
          const isVideoTitleMatch = item.members
            ? item.members.find((member) => {
                console.log(member?.member?.name, "abc");
                return (
                  member?.member &&
                  member?.member?.name
                    ?.toLowerCase()
                    ?.includes(this.searchText?.toLowerCase())
                );
              })
            : null;
          return (
            item?.name?.toLowerCase().includes(value.toLowerCase()) ||
            item?.description?.toLowerCase().includes(value.toLowerCase()) ||
            isVideoTitleMatch
          );
        });
        const isInList = [...this.mediaList].find(
          (media) => media?.id === this.selectedCollection?.id
        );
        console.log(isInList, "isInList");
        if (!isInList) this.selectedCollection = [...this.mediaList][0];
      } else {
        this.mediaList = { ...this.mediaCollections };
      }
    },
    selectedVideo: function (value) {
      console.log(value, "selectedVideo");
    },
  },
};
</script>

<style>
.text-orange {
  color: #feb012;
}

.underline-dash {
  color: #ed8b00;
  border-bottom: 1px dashed;
}

.list-num {
  border: 1px solid #29baad;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  line-height: 20px;
  top: 1px;
  left: -30px;
  margin-right: 10px;
}

.list-circle-box ul {
  list-style: circle;
}

.fasfa-greeting-box ul li {
  margin-top: 10px;
}

.fasfa-greeting-box ul {
  list-style: none;
}

.fasfa-greeting-box ul li::before {
  content: "\2022";
  color: #29baad;
  font-weight: bold;
  display: inline-block;
  width: 12px;
  margin-left: -12px;
}

.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}
</style>
