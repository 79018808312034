<template>
	<button type="button" :class="customClass" class="flex items-center" @click="onClick">
		<Icon name="ChevronLeftIcon" size="5" color="gray-500" />
		<Text size="sm" color="gray-500" class="underline" :content="label"/>
	</button>
</template>

<script>
import Icon from '../../../atoms/Icons/Icons.vue';
import Text from '../../../atoms/Text/Text.vue';
export default {
	components: {
		Icon, Text
	},
	props: ['label', 'customClass', 'onClick']
};
</script>