<template>
  <Template
    v-bind="{
      ...params,
      compareProps: {
        ...params.compareProps,
        selectOptions: getCompareOptions(),
        userSchoolList: this.userVisionList,
        appSchoolList: getAppSchoolList(app),
        isPaid,
      },
      resultProps: {
        ...params.resultProps,
        inSchools: getSchoolList(app, 'In-State'),
        outSchools: getSchoolList(app, 'Out-of-State'),
        app,
        isPaid,
        userVisionList: this.userVisionList,
        updateUserVisionList,
        dashboardButtonsProps: {
          ...params.resultProps.dashboardButtonsProps,
          filterBox: {
            ...params.resultProps.dashboardButtonsProps.filterBox,
            selectProps: {
              ...params.resultProps.dashboardButtonsProps.filterBox.selectProps,
              selectedItem: this.schoolListSortBy,
            },
          },
          barFilters: this.getGpaBuckets(),
        },
        GreetingProps: {
          ...params.resultProps?.GreetingProps,
          title: `Welcome to your dashboard`,
        },
      },
      fafsaCssProps: {
        ...params.fafsaCssProps,
        userSchoolList: this.userVisionList,
      },
      resourceProps: {
        ...params.resourceProps,
        showMoreButton: showMoreButton,
        userVisionList: this.userVisionList,
      },
      courseProps: {
        mediaCollections: this.mediaCollections,
        isPaid,
      },
      backAction: goToApplication,
      showMoreResource: showMoreResource,
      isLoading: isLoading,
      schoolList: json(state.currentFinApp?.schoolList)?.reverse(),
      gpaBucket,
    }"
    @sendMessage="onSend"
    ref="dashboardRef"
  />
</template>
<script>
import { _ } from "vue-underscore";
import Template from "../components/templates/Dashboard/Dashboard";
import { c } from "@/components/constants.js";
import { sortBy, isArray, values } from "underscore";
import { json } from "overmind";
import { get } from "lodash";
import Spinner from "@/components/atoms/Loaders/Spinner/Spinner";
// import {defineAsyncComponent} from 'vue';
//
// const Template = defineAsyncComponent(async () => {
//   await new Promise(r => setTimeout(r, 100))
//   return import('@/components/templates/Dashboard/Dashboard')
// })
export default {
  components: {
    Spinner,
    Template,
  },
  data() {
    return {
      params: c.dashboard,
      app: null,
      isLoading: true,
      showMoreButton: true,
      showUpgrade: false,
      schoolListSortBy: { id: 0, name: "Sort by Upfront Cost (Lowest First)" },
      gpaBucket: "2.0 to 2.9",
      json,
      userSchoolList: [],
      userVisionList: [],
      isPaid: false,
      initialLoading: true,
    };
  },
  methods: {
    async onSend(data) {
      if (!data?.email?.trim()) {
        this.actions.alert.showError({
          message: "Please insert email address!",
        });
        return false;
      }
      if (!data?.content?.trim()) {
        this.actions.alert.showError({ message: "Please insert message!" });
        return false;
      }
      const params = {
        name: "contact",
        subject: data?.subject || "",
        emails: { set: [data?.email] },
        comment: data.content,
      };
      const { saveContactRequest } = await this.actions.user.saveContactRequest(
        { data: params }
      );
      if (saveContactRequest && saveContactRequest.id) {
        this.actions.alert.showSuccess({
          message: "Your message sent successfully",
        });
        this.$refs.dashboardRef.clearContactForm();
      }
    },
    getCompareOptions() {
      const schools = Object.values(this.state.school.schools);
      const data = [];
      schools.map((s) => data.push(s.name));
      return data;
    },
    getAppSchoolList(app) {
      var list = [];
      if (app) {
        if (app["InStateSchools"])
          list = [...list, ..._.values(app["InStateSchools"])];
        if (app["OutOfStateSchools"])
          list = [...list, ..._.values(app["OutOfStateSchools"])];
      }
      return list;
    },
    getSchoolList(app, type) {
      let field = "";
      if (type === "In-State") field = "InStateSchools";
      if (type === "Out-of-State") field = "OutOfStateSchools";
      let list = [];
      if (app && field) list = app[field] ? [...values(app[field])] : [];

      list = list.filter((item) => {
        if (this.gpaBucket) {
          if (
            (item?.gpaBuckets &&
              this.gpaBucket === "2.0 to 2.9" &&
              item?.gpaBuckets?.includes("B2_TO_29")) ||
            (this.gpaBucket === "3.0 to 3.4" &&
              item?.gpaBuckets?.includes("B3_TO_34")) ||
            (this.gpaBucket === "3.5 to 4.0+" &&
              item?.gpaBuckets?.includes("B35_AND_UP")) ||
            (this.gpaBucket === "No Loan Schools" &&
              item?.gpaBuckets?.includes("NO_LOAN_POLICY"))
          )
            return true;
          else return false;
        }
        return true;
      });

      //
      if (this.schoolListSortBy && this.schoolListSortBy.id === 0) {
        list = sortBy(list, function (item) {
          if (item.calculationData && item.calculationData.onCampus) {
            if (isArray(item.calculationData.onCampus.outOfPocket)) {
              return item.calculationData.onCampus.outOfPocket[0];
            } else {
              return item.calculationData.onCampus.outOfPocket;
            }
          } else return item;
        });
      } else if (this.schoolListSortBy && this.schoolListSortBy.id === 1) {
        list = sortBy(list, function (item) {
          if (item.calculationData && item.calculationData.atHome) {
            if (isArray(item.calculationData.atHome.totalGrants)) {
              return -item.calculationData.atHome.totalGrants[0];
            } else return -item.calculationData.atHome.totalGrants;
          } else return item;
        });
      }

      console.log(list, "list");
      list = _.map(list, (i) => {
        if (!i.amount) i.amount = "$ 0";
        if (i?.images?.length > 0) {
          i.logo = i?.images[0];
        } else if (i?.site?.images?.length > 0) {
          i.logo = i?.site?.images[0];
        } else {
          i.logo = {
            url: "https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png",
          };
        }
        return i;
      });

      return {
        title: type + " Schools",
        list,
      };
    },
    schoolListSortChange(sortBy) {
      this.schoolListSortBy = sortBy;
    },
    getGpaBuckets() {
      return this.params.resultProps.dashboardButtonsProps.barFilters.map(
        (item) => {
          return { ...item, current: item.name === this.gpaBucket };
        }
      );
    },
    gpaBucketChange(bucket) {
      console.log(bucket, "bucket");
      this.gpaBucket = bucket.name;
    },
    goToApplication() {
      this.$router.push("/application");
    },
    showMoreResource(e) {
      if (e) e.preventDefault();
      const gloProps = this.params.resourceProps.glossaryProps;
      let oldValue = gloProps.limit;
      //
      this.params.resourceProps.glossaryProps.limit = oldValue + 5;
      if (gloProps && oldValue + 5 >= gloProps.items.length)
        this.showMoreButton = false;
    },
    handleUserSchoolListChange(list) {
      this.userSchoolList = list;
    },
    async updateUserSchoolList(school, type) {
      console.log(school, type, "+++++++++++++");
      var list = [...this.userVisionList];
      const currentFinApp = this.state?.currentFinApp;
      const selectedSchool = currentFinApp?.schoolList?.find(
        (v) => v?.school?.id === school.id
      );
      console.log(selectedSchool, "selectedSchool");
      var params = {};
      if (selectedSchool && selectedSchool.id) {
        params = {
          data: { schoolList: { delete: [{ id: selectedSchool.id }] } },
          where: { id: currentFinApp.id },
        };
        list = list.filter((item) => item.id !== school.id);
      } else {
        params = {
          data: {
            schoolList: {
              create: [
                {
                  school: { connect: { id: school.id } },
                  user: { connect: { id: this.state?.currentUser?.id } },
                  isInStateSchool: type === "inState",
                  isOutStateSchool: type === "outState",
                },
              ],
            },
          },
          where: { id: currentFinApp.id },
        };
        list.push(school);
      }
      console.log(params, "params abc");
      const { saveFinancialApplication } =
        await this.actions.financialApplication.saveFinancialApplication(
          params
        );
      //
      this.actions.setCurrentFinApp(saveFinancialApplication);
      this.userVisionList = list;
    },
    async updateUserVisionList(school, type) {
      var list = [...this.userVisionList];
      const currentFinApp = this.state?.currentFinApp;
      const selectedSchool = currentFinApp?.visionList?.find(
        (v) => v?.school?.id === school.id
      );
      var params = {};
      if (selectedSchool && selectedSchool.id) {
        params = {
          data: { visionList: { delete: [{ id: selectedSchool.id }] } },
          where: { id: currentFinApp.id },
        };
        list = list.filter((item) => item.id !== school.id);
      } else {
        params = {
          data: {
            visionList: {
              create: [
                {
                  school: { connect: { id: school.id } },
                  user: { connect: { id: this.state?.currentUser?.id } },
                  isInStateSchool: type === "inState",
                  isOutStateSchool: type === "outState",
                },
              ],
            },
          },
          where: { id: currentFinApp.id },
        };
        list.push(school);
      }
      console.log(params, "params abc");
      this.userVisionList = list;
      const { saveFinancialApplication } =
        await this.actions.financialApplication.saveFinancialApplication(
          params
        );
      //
      this.actions.setCurrentFinApp(saveFinancialApplication);
    },
  },
  async mounted() {
    if (!this.state.currentFinApp) await this.actions.getUserFinApp();
    //
    let finApp = this.state.currentFinApp;
    this.isPaid = !!json(
      get(this.state.currentUser, "productSubscriptions", [])
    )
      .reverse()
      ?.find((s) => s && s.isActive)?.id;
    if (finApp) {
      console.log(this.state.currentFinApp, "finApp");
      this.userSchoolList =
        _.map(this.state?.currentFinApp?.schoolList, (school) => {
          return { ...school.school, isInStateSchool: school.isInStateSchool };
        }) || [];
      this.userVisionList =
        _.map(this.state?.currentFinApp?.visionList, (school) => {
          return { ...school.school, isInStateSchool: school.isInStateSchool };
        }) || [];

      const result = await this.actions.school.calculateSchoolList({
        appId: finApp.id,
      });
      if (
        result &&
        result.calculateSchoolList &&
        result.calculateSchoolList.id
      ) {
        this.app = result.calculateSchoolList;
        //
        const inStateSchoolsKey = _.keys(
          result.calculateSchoolList.InStateSchools
        );
        const outStateSchoolsKey = _.keys(
          result.calculateSchoolList.OutOfStateSchools
        );
        if (!inStateSchoolsKey.length && !outStateSchoolsKey.length) {
          this.params.resultProps.GreetingProps.button.show = false;
        }
      }
      //
      console.log(result, finApp, this.app, "APP");
    } else {
      console.log("No APPLICATION Found");
    }
    //getMediaCollection
    await this.actions.media.getMediaCollections({ type: "CHANNEL", name_not: "MAIN" });
    this.mediaCollections = this.state.media.mediaCollections;
    console.log(this.mediaCollections, "mediaCollections");
    //
    this.isLoading = false;
  },
};
</script>
