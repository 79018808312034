<template>
  <div>
    <div
      class="
        flex flex-col
        sm:flex-row
        justify-center
        items-center
        sm:justify-start sm:items-start
        gap-4
        mt-6
      "
    >
      <div class="flex-none">
        <Avatar :src="avatar.url" :size="avatar.size" />
      </div>
      <div>
        <Text
          :content="button.show ? content.header : content.paidHeader"
          color="gray-500"
          v-if="isDisplayButton()"
        />
        <Text :content="content.header" color="gray-500" v-else />
        <Button
          @click="() => $emit('goToSubscription')"
          v-if="button && button.show"
          :content="button.label"
          class="my-4"
          :class="`bg-${themeColor}`"
        />
        <Text :content="content.footer" color="gray-500" />
      </div>
    </div>
  </div>
</template>

<script>
import Text from "../../atoms/Text/Text.vue";
import Avatar from "../../atoms/Avatar/Avatar.vue";
import Button from "../../atoms/Button/Button.vue";
import _ from "underscore";

export default {
  components: {
    Text,
    Avatar,
    Button,
  },
  props: {
    themeColor: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: "",
    },
    avatar: {
      type: Object,
      default: () => {},
    },
    content: {
      type: Object,
      default: () => {},
    },
    button: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isDisplayButton() {
      return !_.isEmpty(this.button);
    },
  },
};
</script>
<style>
.dashboard-list {
  list-style: none;
  padding-left: 0px;
}
.dashboard-list li {
  position: relative;
  padding-left: 10px;
}
.dashboard-list li:before {
  content: "-";
  position: absolute;
  left: 0;
}
</style>
