<template>
    <a :href="link" target="_blank" :class="!isAble ? 'cursor-not-allowed no-underline' : 'no-underline'" @click="event => linkPress(event, !isAble)">
        <div class="flex w-72 h-20 rounded" :class="isAble ? 'border-apply-success cursor-pointer' : 'border-apply-gray cursor-not-allowed'">
            <div class="flex w-20 h-20 items-center justify-center relative left-triangle">
                <img :src="img" class="w-16 h-16" />
            </div>
            <div class="flex-1 flex items-center justify-center px-6" :class="isAble ? 'bg-apply-success' : 'bg-apply-gray'">
                <span v-if="label" class="text-white uppercase font-bold text-base text-center">{{label}}</span>
            </div>
        </div>
    </a>
</template>

<script>
    import Icon from "../../atoms/Icons/Icons.vue";
    import Text from "../../atoms/Text/Text.vue";
    import Button from "../Button/WithIcon/WithIcon.vue";

    export default {
        name: "DashboardButtons",
        components: {
            Icon,
            Text,
            Button
        },
        props: {
            img: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            isAble: {
                type: Boolean,
                default: true
            },
            link: {
                type: String,
                default: ""
            }
        },
        methods: {
            linkPress(event, isDisabled) {
                if(isDisabled) event.preventDefault()
            }
        }
    };
</script>


<style>
    .left-triangle:after {
        left: 100%;
        top: 50%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 6px solid hsla(0,0%,100%,0);
        border-left-color: #fff;
        margin-top: -6px;
    }

    .border-apply-gray {
        border: 1px solid #dfe2e8;
    }

    .bg-apply-gray {
        background-color: #dfe2e8;
    }

    .border-apply-success {
        border: 1px solid #29baac;
    }

    .bg-apply-success {
        background-color: #29baac;
    }
</style>