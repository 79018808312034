<template>
  <div class="flex flex-col w-full h-full mt-14">
    <div class="flex flex-col w-full h-full relative">
      <div class="pos-top-border left" />
      <div class="flex flex-col w-full h-full">
        <div class="flex flex-col w-full h-full">
          <Text color="black" weight="bold" size="2xl" :content="title" />
        </div>
        <div
          class="
            flex flex-row flex-wrap
            w-full
            h-full
            gap-1
            justify-start
            mt-4
            justify-between
            alphabet-wrapper
          "
        >
          <div v-for="alphabet in alphabets" :key="alphabet">
            <Button
              :customClass="[
                'border border-gray-300 shadow-sm w-11 ',
                selectedLetter === alphabet
                  ? ' bg-gray-700 text-white'
                  : ' bg-white text-gray-900',
              ]"
              variant="transparent"
              @click="changeLetter(alphabet)"
              size="md"
              :content="alphabet"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full h-full">
        <div
          v-if="filteredItems.length"
          class="flex flex-col border border-gray-300 shadow-sm rounded-md my-2"
          v-for="item in filteredItems"
          :key="item"
        >
          <Items :title="item.title" :description="item.description" />
        </div>
        <Text
          v-else
          color="gray-900"
          class="w-full mt-5 text-center"
          content="No Terms Found"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Items from "../../molecules/ListViewItem/Simple/ListViewItem.vue";
import Button from "../../atoms/Button/Button.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Simple from "../Tables/Simple/Simple.vue";
/**
 * - Use it to show a LinkedRecordList
 */
export default {
  components: {
    Button,
    Items,
    Text,
    Simple,
    Icon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    alphabets: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    limit: { tpye: Number },
  },
  data() {
    return {
      filteredItems: this.items,
      selectedLetter: "",
    };
  },
  methods: {
    changeLetter(letter) {
      this.selectedLetter = letter;
      this.filteredItems = this.items.filter(
        (item) =>
          item.title.charAt(0).toLowerCase() ===
          this.selectedLetter.toLowerCase()
      );
    },
  },
  watch: {
    limit(n) {
      if (this.filteredItems.length != n) {
        if (this.selectedLetter) {
          this.filteredItems = this.items
            .filter(
              (item) =>
                item.title.charAt(0).toLowerCase() ===
                this.selectedLetter.toLowerCase()
            )
            .slice(0, n);
        } else {
          this.filteredItems = this.items.slice(0, n);
        }
      }
    },
  },
  created() {
    if (this.limit) this.filteredItems = this.items.slice(0, this.limit);
  },
};
</script>

<style>
.alphabet-wrapper:after {
  content: "";
  flex: auto;
}
.pos-top-border.left {
  left: 0;
  right: auto;
}

.pos-top-border {
  position: absolute;
  top: -15px;
  width: 50px;
  height: 3px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #29baac;
}
</style>
