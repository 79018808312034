<template>
  <div class="px-3.5 py-5 mx-auto w-7xl max-w-7xl">
    <div class="w-full">
      <TabsInPills :tabs="subTabs" @changeTab="changePillTab" />
    </div>
    <!--    <div class="w-full flex justify-between pb-16 border-b" v-if="currentPillTab == 'FAFSA'">-->
    <!--      <div class="flex-2 py-5 fasfa-greeting-box">-->
    <!--        <Greeting v-bind="fafsaProps.greetingProps"/>-->
    <!--      </div>-->
    <!--      <div class="flex-1 ml-8 py-5">-->
    <!--        <VisionBoard :list="[]"/>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="currentPillTab == 'FAFSA'" class="mb-10">
      <div class="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-3 sm:gap-8">
        <div class="col-span-2 pt-5">
          <Greeting v-bind="fafsaProps.greetingProps" />
        </div>

        <div class="flex-1 sm:ml-36 lg:ml-8 py-5">
          <VisionBoard :list="getVisionList()" />
        </div>
      </div>
      <div class="border-b border-gray-100 pb-2">
        <Text
          content="FAFSA CHECKLIST"
          size="2xl"
          color="gray-800"
          weight="bold"
          class="mt-15 mb-1"
        />
      </div>
      <div class="border-b border-gray-100 pt-3 pb-3">
        <Text
          element="span"
          content="To speed up .. In order to complete the FAFSA, you will need to create an"
          size="sm"
          color="gray-800"
        />
        <Text
          element="span"
          content=" FSA ID"
          size="sm"
          color="orange"
          weight="semibold"
        />
      </div>
      <CheckboxList
        v-model:selected="selectedFafsaList"
        :items="fafsaProps.fafsaCheckList"
        class="mt-5"
      />
      <div class="list-circle-box">
        <Description v-for="item in fafsaProps.descriptions" v-bind="item" />
      </div>
      <a href="https://studentaid.gov/fafsa-app/ROLES" target="_blank">
        <Button
          custom-class="text-base px-6 py-1.5 font-bold"
          :content="fafsaProps.buttonLabel"
          class="my-4"
          :class="`bg-primary`"
        />
      </a>
    </div>
    <div class="w-full" v-else>
      <div class="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-3 sm:gap-8">
        <div class="col-span-2 pt-5">
          <Greeting v-bind="CssProps.greetingProps" />
        </div>
        <div class="flex-1 sm:ml-36 lg:ml-8 py-5">
          <VisionBoard :list="visionList" />
        </div>
      </div>
      <Text
        :content="CssProps.subContent.title"
        size="2xl"
        color="gray-800"
        weight="bold"
      />
      <Text :content="CssProps.subContent.desc" size="base" color="gray-800" />
      <div class="list-circle-box">
        <Description v-for="item in CssProps.descriptions" v-bind="item" />
      </div>
      <a href="https://cssprofile.collegeboard.org/" target="_blank">
        <Button
          custom-class="text-base px-6 py-1.5 font-bold"
          :content="CssProps.buttonLabel"
          class="my-4"
          :class="`bg-primary`"
        />
      </a>
    </div>
  </div>
</template>

<script>
import TabsWithTriangleRightBorder from "../../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../../molecules/Greeting/Greeting.vue";
import Calendar from "../../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import TabsInPills from "../../../molecules/Tabs/TabsInPills/TabsInPills.vue";
import Description from "../../../organisms/TermsAndPolicy/ContentItem/Item.vue";
import VisionBoard from "../../../molecules/VisionBoard/VisionBoard.vue";
import CheckboxList from "../../../molecules/Checkboxes/SimpleList/SimpleList.vue";
import { _ } from "vue-underscore";

export default {
  components: {
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    TabsInPills,
    Description,
    VisionBoard,
    CheckboxList,
  },
  props: {
    subTabs: {
      type: Array,
      default: () => [],
    },
    fafsaProps: {
      type: Object,
      default: null,
    },
    CssProps: {
      type: Object,
      default: null,
    },
    userSchoolList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPillTab: "",
      visionList: _.first([...this.userSchoolList], 12),
      selectedFafsaList: [],
    };
  },
  created() {
    this.currentPillTab = this.subTabs.find((tab) => tab.current).name;
  },
  methods: {
    changePillTab(tab) {
      this.currentPillTab = tab.name;
    },
    getVisionList() {
      return _.first([...this.visionList], 12);
    },
  },
  watch: {
    userSchoolList: function (value) {
      this.visionList = _.first([...value], 12);
    },
  },
};
</script>

<style>
.text-orange {
  color: #feb012;
}

.underline-dash {
  color: #ed8b00;
  border-bottom: 1px dashed;
}

.list-num {
  border: 1px solid #29baad;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  line-height: 20px;
  top: 1px;
  left: -30px;
  margin-right: 10px;
}

.list-circle-box ul {
  list-style: circle;
}

.fasfa-greeting-box ul li {
  margin-top: 10px;
}

.fasfa-greeting-box ul {
  list-style: none;
}

.fasfa-greeting-box ul li::before {
  content: "\2022";
  color: #29baad;
  font-weight: bold;
  display: inline-block;
  width: 12px;
  margin-left: -12px;
}

.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}
</style>
