<template>
  <div class="flex m-auto" style="max-width: 600px">
    <Vue3Lottie :animationData="animationData" />
  </div>
</template>
<script>
import { c } from "@/components/constants.js";
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";

export default {
  components: { Vue3Lottie },
  data() {
    return {
      animationData: c.calculateData,
    };
  },
};
</script>