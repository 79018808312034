
<template>
  <div class="flex flex-col space-y-1 sm:hidden">
    <Button
      v-for="(tab, id) in tabs"
      :key="id"
      :content="tab.name"
      @click="onSelect(tab.id)"
      :variant="tab.id === selectedId ? 'primary' : 'secondary'"
      :class="[tab.id === selectedId ? 'bg-primary' : 'text-primary']"
    />
  </div>
  <div
    class="
      flex-row
      items-center
      hidden
      w-full
      px-2
      space-x-8
      sm:flex sm:px-0 sm:mb-m-1
    "
  >
    <button
      v-for="(tab, id) in tabs"
      :key="id"
      class="relative border-t border-l outline-none rounded-t-md"
      :class="tab.id === selectedId ? 'bg-white z-40' : 'bg-gray-100 border-b'"
      @click="onSelect(tab.id)"
    >
     <Text
        size="sm xl:text-lg"
        weight="medium"
        :color="tab.id === selectedId ? 'primary' : 'gray-800'"
        :content="tab.name"
        class="py-2 lg:py-3 px-2 lg:px-4 xl:px-8 whitespace-nowrap font-custom"
      />
        <div
        :class="`absolute top-0 w-8 lg:w-9 xl:w-10 h-9 lg:h-11 xl:h-13 -z-1 transform border-r rounded-tr-md skew-x-55 -right-20px lg:-right-10px  ${
          tab.id === selectedId ? 'bg-white' : 'bg-gray-100'
        }`" 
      />
    </button>
  </div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
/**
 * - Use it when show tabs with triangle right border
 */
export default {
  components: {
    Text,
    Button,
  },
  props: {
    /**
     * the list of tabs
     */
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },
    selectedTabId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      selectedId: this.selectedTabId,
    };
  },
  watch: {
    selectedTabId: function (val) {
      this.selectedId = val;
    },
  },
  methods: {
    onSelect(id) {
      this.selectedId = id;
      this.$emit("changeTab", id);
    },
  },
};
</script>

<style>
@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}

.h-52px {
  height: 52px;
}

.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.-right-20px {
  right: -20px !important;
}

.z-60 {
  z-index: 60;
}

.font-custom {
  font-family: Montserrat, Poppins, Arial, sans-serif;
  letter-spacing: 0.01em;
}
</style>
