<template>
    <div class="flex flex-col p-4">
        <div class="flex flex-row items-center justify-start w-full" >
            <div class="flex gap-2 item-center">
                <Text color="black" weight="bold" size="2xl" :content="title" />
            </div>
        </div>
        <div class="flex flex-row items-center mt-4 justify-start w-full" >
            <Text color="black" :content="description" />
        </div>
    </div>
</template>

<script>
    import Text from "../../../atoms/Text/Text.vue";

    export default {
        components: {
            Text,
        },
        name: "ListViewItem",
        props : {
            title: {
                type: String,
                default: ""
            },
            description: {
                type: Number,
                default: 0
            },
        }

    }
</script>