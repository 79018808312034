<template>
  <div class="max-w-md w-full" :class="label ? 'space-y-2': ''" v-click-outside="() => isOpen = false">
    <Text size="xs" weight="semibold" :content="label" v-if="label" />
    <div @click="() => {isOpen = !isOpen; $emit('onFocus')}"
      class="relative z-0 flex flex-wrap flex-shrink w-full px-2 py-2 border rounded-md min-h-10 hover:border-indigo-600"
      style="min-height: 40px;">
      <div v-for="item in selectedItems" :key="item"
        :class="`flex items-center justify-center h-full px-2 py-1 m-1 text-xs text-white bg-indigo-600 rounded bg-${btnColor}`">
        {{item}}
        <Icon @click.stop="onRemove(item)" name="XIcon" size="3" color="white" customClass="ml-1 cursor-pointer" />
      </div>
      <Icon :name="isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'" color="gray-500" size="5" customClass="absolute right-3 top-3"/>
    </div>
    <div v-if="isOpen" class="z-30 bg-white border absolute pl-2.5 pr-8">
      <input @keyup.enter="handleAdding" ref="keyword" type="text" v-model="keyword"
        class="w-full px-2 py-2 text-sm border-none outline-none" placeholder="Type to find an option" />
      <a v-for="(item, id) in filterItems()" :key="item" @click="onSelect(item)"
        :class="(keyword && id === 0) ? `bg-gray-100 hover:bg-${btnColor}` : `hover:bg-${btnColor}`"
        class="flex items-center w-full px-2 py-2 text-sm cursor-pointer hover:bg-indigo-600 hover:text-white">
        {{item}}
      </a>
      <div class="mx-2 bg-gray-200" style="height: 1px;"></div>
      <button class="flex items-center w-full px-2 py-2 text-sm text-green-500 hover:bg-indigo-600 hover:text-white"
        @click="onAdd" :class="keyword && filterItems().length === 0 && 'bg-gray-100'">
        + Add {{keyword && keyword.length > 0 ? `"${keyword}""` : 'New'}}
      </button>
    </div>
  </div>

</template>

<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Text from '../../../atoms/Text/Text.vue'
import vClickOutside from 'click-outside-vue3'


export default {
  components: {
    Icon,
    Text
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onAdd() {
      if (this.keyword) {
        this.selectedItems.push(this.keyword);
        this.updateSelected();
        this.items.push(this.keyword);
        this.keyword = null;
      } else {
        this.$refs.keyword.focus()
      }
    },
    onSelect(item) {
      this.selectedItems.push(item);
      this.updateSelected();
    },
    filterItems() {
      const items = this.items.filter(item => !this.selectedItems.find(s => s === item));
      if (this.keyword && items?.length > 0) {
        return items.filter(i => i?.toLowerCase()?.includes(this.keyword?.toLowerCase()))
      } else {
        return items;
      }
    },
    onRemove(item) {
      const index = this.selectedItems.findIndex(s => s === item);
      if (index > -1) {
        this.$emit('onRemove', item)
        this.selectedItems.splice(index, 1);
        this.updateSelected();
      }
    },
    handleAdding() {
      if (this.keyword?.length > 0 && this.filterItems().length === 0) {
        this.selectedItems.push(this.keyword);
        this.updateSelected();
        this.items.push(this.keyword);
        this.keyword = null;
      } else if (this.keyword?.length > 0 && this.filterItems().length > 0) {
        const item = this.filterItems()[0];
        this.selectedItems.push(item);
        this.updateSelected();
        this.items.push(item);
        this.keyword = null;
      }
    },
    updateSelected() {
      this.$emit("update:selected", this.selectedItems);
    },
  },
  data() {
    return {
      items: this.options,
      selectedItems: [],
      keyword: null,
      isOpen: false,
    }
  },
  mounted() {
    this.selectedItems = this.selected
  },
  watch: {
    'options': function (optList) {
      this.items = optList
      this.selectedItems = [...this.selectedItems].filter(item => optList.find(opt => opt === item))
    }
  },
}
</script>
