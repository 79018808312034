
<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="
          bg-gray-50
          border border-gray-300
          text-gray-900 text-sm
          rounded-lg
          focus:ring-blue-500 focus:border-blue-500
          block
          w-full
          px-3
          py-2
          dark:bg-gray-700
          dark:border-gray-600
          dark:placeholder-gray-400
          dark:text-white
          dark:focus:ring-blue-500
          dark:focus:border-blue-500
          tab-select
        "
        @change="changeTab($event, '', 'select')"
      >
        <option
          v-for="tab in tabList"
          :key="tab.name"
          :value="tab.name"
          :selected="tab.current"
        >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <a
          v-for="tab in tabList"
          :key="tab.name"
          :href="tab.href"
          :class="[
            tab.current
              ? 'bg-gray-100 text-gray-700'
              : 'text-gray-500 hover:text-gray-700',
            'px-3 py-2 font-medium text-sm rounded-md',
          ]"
          @click="changeTab($event, tab)"
          :aria-current="tab.current ? 'page' : undefined"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
/**
 * - Use it when show tabs in pills
 * - tabs is required. default is empty array
 */
export default {
  props: {
    /**
     * the list of tabs
     */
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      tabList: this.tabs,
    };
  },
  methods: {
    changeTab(e, tab, type) {
      e.preventDefault();
      this.tabList = this.tabList.map((item) => {
        return {
          ...item,
          current:
            item.name === (type === "select" ? e.target.value : tab.name)
              ? true
              : false,
        };
      });
      this.$emit(
        "changeTab",
        type === "select"
          ? this.tabList.find((item) => item.name == e.target.value)
          : tab
      );
    },
  },
};
</script>
<style scoped>
.tab-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
}
</style>
