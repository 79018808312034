<template>
    <div>
        <Text size="sm" weight="semibold" color="gray-500" :content="label" />
        <div class="relative max-w-md">
            <div class="absolute w-full py-0.5 rounded-full flex justify-between items-center relative z-50">
                <div class="border border-1 border-gray-600 w-full absolute h-1.5 rounded-full"
                    style="border-width: 2px !important" />
                <div class="h-5 rounded-full w-2 border border-gray-400 bg-white node z-50 mx-10"></div>
                <div class="h-5 rounded-full w-2 border border-gray-400 bg-white node z-50 mx-10"></div>
                <div class="h-5 rounded-full w-2 border border-gray-400 bg-white node z-50 mx-10"></div>
                <div class="h-5 rounded-full w-2 border border-gray-400 bg-white node z-50 mx-10"></div>
                <div class="h-5 rounded-full w-2 border border-gray-400 bg-white node z-50 mx-10"></div>
            </div>
            
            <div class="w-full bg-gray-50 shadow-lg -mt-4">
                <div class="flex bg-white px-3 pt-4 pb-2 border-b w-full">
                    <div class="flex items-center w-7/8 relative">
                        <Icon name="ChevronLeftIcon" color="black" size="5" @click="onPrev" customClass="mt-3"/>
                        <Swiper @swiper="setSwiper" :slides-per-view="6" :slidesPerGroup="3" :modules="modules" :space-between="0"
                            :navigation="{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }" class="w-full">
                            <SwiperSlide v-for="(month, id) in months" :key="month">
                                <div class="flex flex-col items-center justify-center px-1" :class="month === 'DEC' && 'border-r'">
                                    <div class="flex items-end h-4 px-2" :class="month === 'DEC' ? 'justify-end' : 'justify-start'">
                                        <Text v-if="month === 'DEC' || month === 'JAN'" color="gray-400" :content="month === 'DEC' ? moment().format('YYYY') : moment().add(1, 'Y').format('YYYY')" style="font-size: 9px;"/>
                                    </div>
                                    <div class="px-2 py-1 rounded-md text-xs font-semibold cursor-pointer"
                                        :class="selectedMonth === month ? 'border border-primary text-primary' : ''"
                                        @click="selectedMonth = month">
                                        {{month}}
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <Icon name="ChevronRightIcon" color="black" size="5" @click="onNext" customClass="mt-3"/>    
                    </div>
                    <div class="flex justify-end items-center w-1/8">
                        <a class="cursor-pointer mt-3" @click="isOpen = true">
                            <Icon name="MenuIcon" />
                        </a>
                    </div>
                </div>
                <div class="h-56 w-full bg-white overflow-y-scroll">
                    <div v-if="!isShowView && !isShowSelect">
                        <div v-for="(item, id) in items" :key="item.id" class="border-b" :class="[id % 2 === 1 ? 'bg-white' : 'bg-gray-100', id === selectedTask && 'divide-y']">
                        <div class="px-3 py-3 flex justify-between items-center cursor-pointer" @click="selectedTask = (selectedTask === id ? null : id)">
                            <div class="flex items-center">
                                <Text size="xs" color="gray-400" :content="item.date" customClass="w-12"/>
                                <Text size="xs" color="gray-600" :content="item.label"/>
                            </div>
                            <a>
                                <Icon :name="id === selectedTask ? 'ChevronDownIcon' : 'ChevronRightIcon'" color="gray-400" size="6"/>
                            </a>
                        </div>
                        <div class="bg-white transition-all" :class="id === selectedTask ? 'menu' : 'overflow-hidden hide'">
                            <div class="px-5 pt-5 pb-3 ">
                                <Button variant="secondary" content="Complete this task" customClass="w-full border-primary text-primary font-semibold hover:text-opacity-80"/>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                    <div v-else-if="isShowView" class="px-2 py-3 h-full">
                        <a class="text-sm font-medium text-gray-400 hover:text-primary" @click="isShowView = false">Back to Upcoming Deadlines</a>
                        <div class="flex justify-center items-center">
                            <Text size="xl" weight="medium" color="gray-300" content="No completed events found" customClass="mt-16"/>
                        </div>
                    </div>
                    <div v-else-if="isShowSelect" class="px-4 py-5">
                        <Text size="base" color="gray-500" weight="medium" content="Please select your School Year." customClass="text-center"/>
                        <div class="grid grid-cols-2 gap-4 mt-6">
                            <button @click="() => {isShowSelect = false; isShowView = true}" v-for="button in buttons" class="flex justify-center items-center py-2 w-full border rounded-md col-span-1 space-x-2">
                                <Icon name="UserIcon" color="gray-400"/>
                                <Text size="base" color="gray-400" :content="button.label"/>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="absolute h-full w-full transition-all top-0 z-10 right-0 shadow-lg z-10 bg-white" :class="isOpen ? 'sidebar_open' : 'sidebar'">
                <div class="bg-gray-100 border-b flex justify-between items-center px-5 py-4 mt-4 w-full transition-all" :class="isOpen ? 'opacity-100' : 'opacity-0'">
                    <Text size="sm" weight="semibold" color="gray-600" content="MENU"/>
                    <a @click="isOpen = false">
                        <Icon name="XIcon" color="gray-700" size="5"/>
                    </a>
                </div>
                <div class="px-3 py-5 flex flex-col space-y-3 transition-all" :class="isOpen ? 'opacity-100' : 'opacity-0'">
                    <a v-for="item in menuItems" @click="() => onClickMenu(item.id)" class="text-sm font-semibold text-gray-400 hover:text-primary no-underline cursor-pointer">{{item.label}}</a>
                </div>
            </div> 
            
        </div>
    </div>
</template>

<script>
import Text from "../../atoms/Text/Text.vue";
import Icon from '../../atoms/Icons/Icons.vue'
import Button from '../../atoms/Button/Button.vue'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import moment from 'moment'
import { Navigation, Pagination } from 'swiper';
import { ref } from 'vue';


export default {
    components: {
        Text,
        Icon,
        Swiper,
        SwiperSlide,
        Button
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        menuItems: {
            type: Array,
            default: () => []
        },
        buttons: {
            type: Array,
            default: () => []
        }
    },
    data() {
        const swiper = ref(null);
        const setSwiper = (e) => {
            swiper.value = e;
        };
        return {
            months: [],
            selectedMonth: moment().format('MMM')?.toUpperCase(),
            modules: [Navigation, Pagination],
            swiper,
            setSwiper,
            moment,
            selectedTask: null,
            isOpen: false,
            isShowView: false,
            isShowSelect: true,
        }
    },
    methods: {
        onPrev() {
            this.swiper.slidePrev()
        },
        onNext() {
            this.swiper.slideNext()
        },
        onClickMenu(id) {
            if (id === 0) {
                this.isShowView = true;
                this.isOpen = false;
            } else {
                this.isShowSelect = true;
                this.isOpen = false;
            }
        }
    },
    mounted() {
        for (let i = 0; i < 12; i++) {
            this.months.push(moment().add(i, 'M').format('MMM')?.toUpperCase())
        }
    }

}
</script>
<style>
.node {
    border-width: 3px !important;
}

.swiper-button-next {
    position: absolute;
    background-size: 12px 20px;
    background-repeat: no-repeat;
    top: 5px;
    right: -7px;
    width: 20px;
    height: 20px;
    z-index: 50;
}

.swiper-button-prev {
    position: absolute;
    background-size: 12px 20px;
    background-repeat: no-repeat;
    left: auto;
    top: 5px;
    left: -5px;
    width: 20px;
    height: 20px;
    z-index: 50;
}
.hide {
    max-height: 0px;
}
.menu {
    max-height: 80px;
}
.sidebar {
    max-width: 0;
}
.sidebar_open {
    max-width: 170px;
}
</style>