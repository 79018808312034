<template>
  <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
<!--    <div class="flex-1 px-4">-->
<!--      <div class="bg-cgray-200 w-full h-full max-w-sm p-5">-->
<!--        <p class="text-cblack mb-5 text-xl">How to Use FAID</p>-->
<!--        <VideoView :video_id="video_id"/>-->
<!--      </div>-->
<!--    </div>-->
    <div class="flex-1">
      <div class="bg-cgray-200 w-full h-full">
        <div class="faq-bg-container flex justify-center relative">
          <img src="../../../assets/public/images/faq-ed-face.png" class="face-img"/>
        </div>
        <div class="p-2.5 flex items-center flex-col">
          <p class="text-cblack mt-1 mb-8 text-xl">Frequently Asked Questions</p>
          <Button variant="primary" content="See FAQS" onclick="this.$emit('goToFaq')"
                  class="px-4 py-2 font-semibold text-md shadow-lg bg-primary text-white"/>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="bg-cgray-200 w-full h-full p-5">
        <p class="text-cblack mb-4 text-xl">Send us a message</p>
        <Input type="email" v-model:value="item.email" class="w-full mb-2" placeholder="Enter email"/>
        <Input type="text" v-model:value="item.subject" class="w-full mb-2" placeholder="Enter subject"/>
        <Textarea type="textarea" v-model:value="item.content" class="w-full mb-2"
                  placeholder="Type the message you wish to send"/>
        <Button variant="primary" content="Submit" @click="() => $emit('sendMessage', item)"
                class="px-4 py-2 font-semibold text-md shadow-lg bg-primary text-white"/>
      </div>
    </div>
  </div>
</template>

<script>
import VideoView from '../../molecules/Content/VideoViewWithModal/VideoViewWithModal.vue';
import Button from "../../atoms/Button/Button.vue";
import Input from '../../molecules/Inputs/Component/Component.vue';
import Textarea from '../../molecules/Textareas/Simple/Simple.vue';

export default {
  components: {
    Button,
    VideoView,
    Input,
    Textarea
  },
  emits: ['sendMessage'],
  props: {
    video_id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      item: {
        email: "",
        content: "",
        subject: ""
      }
    }
  },
  methods: {
    clearFormData() {
      this.item = { email: "", content: "", subject: "" }
    }
  }
};
</script>

<style>
.bg-cgray-200 {
  background: #f1f2f6;
}

.text-cblack {
  color: #3a404d;
}

.faq-bg-container {
  background-image: url("../../../assets/public/images/faq-res-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #dee1e8;
}

.faq-bg-container:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #f1f2f6 transparent transparent;
  border-width: 10px;
  margin-left: -10px;
  top: 100%;
  left: 50%;
}


.faq-bg-container:before {
  top: 100%;
  left: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #dee1e8 transparent transparent;
  border-width: 12px;
  margin-left: -12px;
}

.face-img {
  max-width: 150px;
  width: 100%;
  margin-top: -15px;
}
</style>
