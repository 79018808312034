<template>
  <div class="px-3.5 py-5 mx-auto w-7xl max-w-7xl">
<!--    <div class="w-full flex justify-between pb-16">-->
<!--      <div class="flex-2 py-5">-->
<!--        <Greeting v-bind="GreetingProps"/>-->
<!--      </div>-->
<!--      <div class="flex-1 ml-8 mt-5">-->
<!--        &lt;!&ndash;        <Calendar v-bind="calendarProps"/>&ndash;&gt;-->
<!--        <VisionBoard :list="[]"/>-->
<!--      </div>-->
<!--    </div>-->
    <div class="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-3 lg:gap-y-8 pb-16">
      <div class="col-span-2">
        <Greeting v-bind="GreetingProps"/>
      </div>
      <div class="flex-1 sm:ml-36 lg:ml-10 mt-8 sm:mt-0">
        <VisionBoard :list="getVisionList()"/>
      </div>
    </div>
    <div class="w-full">
      <InfoBoxes v-bind="infoBoxProps" @sendMessage="message => $emit('sendMessage', message)" ref="formRef" />
      <Glossary v-bind="glossaryProps"/>
      <Button variant="primary" :content="showMoreLabel" v-if="showMoreButton"
              class="px-16 py-4 font-semibold text-md shadow-lg btn-868c97 w-full mt-8"
              :onClick="(e) => this.$emit('handleShowMore', e)"/>
    </div>
  </div>
</template>

<script>
import TabsWithTriangleRightBorder
  from "../../../molecules/Tabs/TabsWithTriangleRightBorder/TabsWithTriangleRightBorder.vue";
import Greeting from "../../../molecules/Greeting/Greeting.vue";
import Calendar from "../../../molecules/Calendar/Calendar.vue";
import DashboardButtons from "../../../molecules/DashboardButtons/DashboardButtons.vue";
import SchoolCard from "../../../molecules/Cards/SchoolCard/SchoolCard.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from '../../../atoms/Button/Button.vue';
import TabsInPills from "../../../molecules/Tabs/TabsInPills/TabsInPills.vue";
import Description from "../../../organisms/TermsAndPolicy/ContentItem/Item.vue";
import InfoBoxes from "../../InfoBoxes/InfoBoxes.vue";
import Glossary from "../../Glossary/Glossary.vue";
import VisionBoard from "../../../molecules/VisionBoard/VisionBoard.vue";
import {_} from "vue-underscore";

export default {
  components: {
    Glossary,
    InfoBoxes,
    TabsWithTriangleRightBorder,
    Greeting,
    Calendar,
    DashboardButtons,
    SchoolCard,
    Text,
    Button,
    TabsInPills,
    Description,
    VisionBoard
  },
  props: {
    GreetingProps: {
      type: Array,
      default: null
    },
    calendarProps: {
      type: Array,
      default: null
    },
    infoBoxProps: {
      type: Array,
      default: null
    },
    glossaryProps: {
      type: Array,
      default: null
    },
    showMoreLabel: {
      type: String,
      default: ""
    },
    handleShowMore: {
      type: Function
    },
    showMoreButton: {
      type: Boolean
    },
		userVisionList: {
			type: Array,
			default: () => []
		}
  },
	methods: {
		getVisionList() {
			return _.first([...this.visionList], 12)
		},
    clearContactForm() {
      this.$refs?.formRef?.clearFormData()
    }
	},
	data() {
		return {
			visionList: this.userVisionList
		}
	},
};
</script>

<style>
.text-orange {
  color: #feb012;
}

.underline-dash {
  color: #ed8b00;
  border-bottom: 1px dashed;
}

.list-num {
  border: 1px solid #29baad;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  line-height: 20px;
  top: 1px;
  left: -30px;
  margin-right: 10px;
}

.list-circle-box ul {
  list-style: circle;
}

.fasfa-greeting-box ul li {
  margin-top: 10px;
}

.fasfa-greeting-box ul {
  list-style: none;
}

.fasfa-greeting-box ul li::before {
  content: "\2022";
  color: #29baad;
  font-weight: bold;
  display: inline-block;
  width: 12px;
  margin-left: -12px;
}

.btn-868c97 {
  background: white;
  border: 1px solid #868c97;
  color: #868c97;
}

.btn-868c97:hover {
  background: #868c97;
  color: white;
}

.school-card-container {
  flex: 1 1 calc(50% - 12px);
}

@media (min-width: 640px) {
  .sm\:mb-m-1 {
    margin-bottom: -1px !important;
  }
}
</style>
