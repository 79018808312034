<template>
  <button
    :type="type"
    :class="`border-${borderWidth} border-${borderColor} shadow-sm  items-center btn-${size || 'md'} btn-${variant||'primary'} ${noRoundedBorders? 'rounded-none':rounded} ${customClass}`"
    class="inline-flex items-center justify-center transition duration-150 ease-in-out shadow-sm focus:outline-none focus:z-10"
  >
    <Icon
      v-if="leading"
      :name="iconName"
      :size="iconSize"
      :color="textColor"
      :hover-text-color="hoverIconColor"
      :is-outline="isOutlineIcon"
      :custom-class="`${label ? 'md:mr-3' : ''}`"
    />
    <span :class="`hidden md:block text-${textColor}`">{{ label }}</span>
    <Icon
      v-if="!leading"
      :name="iconName"
      :size="iconSize"
      :color="textColor"
      :isOutline="isOutlineIcon"
      :customClass="`${label ? 'md:ml-3' : ''} flex-shrink-0`"
    />
  </button>
</template>

<script>
import { computed } from "vue";
import Icon from "../../../atoms/Icons/Icons.vue";
/**
- Use a button for actions within applications
- different variants i.e. primary, secondary etc. and sizes supported
**/
export default {
  name: "WithIconButton",
  components: {
    Icon,
  },
  props: {
    /**
    used to provide type like button or submit. By default button is set.
    */
    type: {
      type: String,
      default: "button",
    },
    /**
    Button comes in different sizes like  sm, md, lg and xl. By default md is set.
    */
    size: {
      type: String,
      default: "",
    },
    /**
    switch between different variants i.e. primary, secondary, success, danger and themed
    */
    variant: {
      type: String,
      default: "",
    },
    /**
    Used this prop to assign custom classes to button
    */
    customClass: {
      type: String,
      default: "",
    },
    /**
    Text to be shown within button
    */
    label: {
      type: String,
      default: "",
    },
  
    /**
    Used for rounding corners of buttons. if set rounded-full is applied otherwise rounded is applied
    */
    isRounded: {
      type: Boolean,
      default: false,
    },
    borderWidth: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "transparent",
    },
    //icon Properties
    iconName: {
      type: String,
      default: "",
    },
    isOutlineIcon: Boolean,
    iconSize: {
      type: String,
      default: "5",
    },
    textColor: {
      type: String,
      default: "white",
    },
    hoverIconColor: {
      type: String,
      default: "",
    },
    leading: {
      type: Boolean,
      default: false,
    },
    noRoundedBorders: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      rounded: computed(() => {
        return `${props.isRounded ? "rounded-full" : "rounded"}`;
      }),
    };
  },
};
</script>

<style>
  .btn-primary {
    @apply bg-indigo-600 text-white hover:bg-indigo-500
  }
  .btn-secondary {
    @apply border border-gray-300 rounded-md text-gray-700 bg-white hover:text-gray-500 
  }
  .btn-success {
   @apply bg-green-500 hover:bg-green-700 text-white
  }
  .btn-danger {
    @apply bg-red-500 hover:bg-red-700 text-white
  }
  .btn-themed {
    @apply bg-primary hover:opacity-80 text-white hover:opacity-80
  }
  .btn-sm {
    @apply px-2 py-0 text-xs font-medium
  }
  .btn-md {
    @apply px-4 py-2 text-sm font-medium
  }
  .btn-lg {
    @apply px-6 py-2 text-base font-medium
  }
  .btn-xl {
    @apply px-8 py-3 text-base font-medium
  }
</style>
