<template>
  <div>
    <div class="flex flex-col lg:flex-row h-16 gap-6 justify-between">
      <div
        v-for="item in buttonData"
        @click="clickBtn(item)"
        v-if="showButtons"
      >
        <Button
          v-if="item.iconName"
          :label="item.title"
          :iconName="item.iconName"
          :leading="true"
          :customClass="[
            'px-8 h-full border shadow-md ',
            isActiveFilter && item.title === 'Filter'
              ? ` bg-${item.color} text-white`
              : ` bg-white text-${item.color}`,
          ]"
          :textColor="
            isActiveFilter && item.title === 'Filter' ? 'white' : item.color
          "
        />
        <button
          v-else
          class="h-full text-center border rounded-md shadow-md px-36"
        >
          <Text
            :content="item.title"
            weight="semibold"
            size="lg"
            :color="item.color"
          />
          <Text :content="item.content" size="sm" color="gray-500" />
        </button>
      </div>
      <div class="flex-1 mr-0 sm:mr-10">
        <div class="">
          <h3 class="mb-5 font-semibold">
            Choose different GPA ranges to see which schools are a fit for you!
          </h3>
          <nav
            class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
            aria-label="Tabs"
          >
            <a
              v-for="(tab, tabIdx) in barFilters"
              :key="tab.name"
              :href="tab.href"
              @click="clickTabFilter($event, tab)"
              :class="[
                tab.current
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === barFilters.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-visible bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 no-underline whitespace-nowrap',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
            >
              <div class="text-xs sm:text-sm overflow-hidden">
                {{ `${tab.name}` }}
                <span class="hidden sm:inline">{{
                  `(${getTotalSchools(tab)})`
                }}</span>
              </div>

              <Badge
                :is-rounded="true"
                text-size="xs"
                px="1"
                py="1"
                bg-color="primary"
                text-color="white"
                :label="getTotalSchools(tab)"
                class="count-badge sm:hidden inline-flex items-center justify-center w-5 h-5"
              />
              <span
                aria-hidden="true"
                :class="[
                  tab.current ? 'bg-primary' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5',
                ]"
              />
            </a>
          </nav>
        </div>
      </div>
      <div>
        <CustomSelect
          v-bind="filterBox.selectProps"
          :selectionChanged="(item) => selectionChanged(item)"
          class="mt-0 lg:mt-14"
        />
      </div>
    </div>
    <!--    <div>-->
    <!--      <div class="">-->
    <!--        <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">-->
    <!--          <a v-for="(tab, tabIdx) in barFilters" :key="tab.name" :href="tab.href" @click="clickTabFilter($event,tab)"-->
    <!--             :class="[tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === barFilters.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 no-underline whitespace-nowrap']"-->
    <!--             :aria-current="tab.current ? 'page' : undefined">-->
    <!--            <span>{{ tab.name }}</span>-->
    <!--            <span aria-hidden="true"-->
    <!--                  :class="[tab.current ? 'bg-primary' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"/>-->
    <!--          </a>-->
    <!--        </nav>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <CustomSelect v-bind="filterBox.selectProps" :selectionChanged="(item) => selectionChanged(item)"/>-->
    <!--    </div>-->
  </div>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-out"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="isActiveFilter"
      class="w-full mt-2 border rounded bg-white p-5 shadow-md flex space-x-8 flex items-center"
    >
      <CustomSelect v-bind="filterBox.selectProps" />
      <label>
        <input
          v-model="isPrivate"
          aria-describedby="comments-description"
          type="checkbox"
          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        {{ filterBox.privateLabel }}
      </label>
      <label>
        <input
          v-model="isPublic"
          aria-describedby="comments-description"
          type="checkbox"
          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        {{ filterBox.publicLabel }}
      </label>
    </div>
  </transition>
  <CollegeListModal
    v-bind="{ ...modalProps, isOpen: isActiveMyList }"
    :onClose="() => (isActiveMyList = false)"
  />
</template>

<script>
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Button from "../Button/WithIcon/WithIcon.vue";
import CustomSelect from "../Selects/Component/Component.vue";
import CollegeListModal from "../../organisms/Modals/CollegeListModal/CollegeListModal.vue";
import Badge from "../../molecules/Badge/Basic/Basic.vue";
import { _ } from "vue-underscore";
import { sortBy, isArray } from "lodash";

export default {
  name: "DashboardButtons",
  components: {
    Icon,
    Text,
    Button,
    CustomSelect,
    CollegeListModal,
    Badge,
  },
  props: {
    buttons: {
      type: Object,
      default: () => {},
    },
    filterBox: {
      type: Object,
      default: () => {},
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    barFilters: {
      type: Object,
      default: () => {},
    },
    app: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      buttonData: this.buttons,
      isActiveFilter: false,
      isActiveMyList: false,
      isPrivate: false,
      isPublic: false,
      showButtons: false,
      schoolListSortBy: { id: 0, name: "Sort by Upfront Cost (Lowest First)" },
    };
  },
  methods: {
    getSchoolList(app, type, tab) {
      let field = "";
      if (type === "In-State") field = "InStateSchools";
      if (type === "Out-of-State") field = "OutOfStateSchools";
      let list = [];
      if (app && field) list = app[field] ? [..._.values(app[field])] : [];

      list = list.filter((item) => {
        if (tab) {
          if (
            (item?.gpaBuckets &&
              tab.name === "2.0 to 2.9" &&
              item?.gpaBuckets?.includes("B2_TO_29")) ||
            (tab.name === "3.0 to 3.4" &&
              item?.gpaBuckets?.includes("B3_TO_34")) ||
            (tab.name === "3.5 to 4.0+" &&
              item?.gpaBuckets?.includes("B35_AND_UP")) ||
            (tab.name === "No Loan Schools" &&
              item?.gpaBuckets?.includes("NO_LOAN_POLICY"))
          )
            return true;
          else return false;
        }
        return true;
      });
      //
      // //
      // if(this.schoolListSortBy && this.schoolListSortBy.id === 0) {
      //   list = sortBy(list, function(item) {
      //     if (item.calculationData && item.calculationData.onCampus) {
      //       if (isArray(item.calculationData.onCampus.outOfPocket)) {
      //         return item.calculationData.onCampus.outOfPocket[0]
      //       } else {
      //         return item.calculationData.onCampus.outOfPocket
      //       }
      //     } else return item;
      //   });
      // } else if(this.schoolListSortBy && this.schoolListSortBy.id === 1) {
      //   list = sortBy(list, function(item) {
      //     if (item.calculationData && item.calculationData.atHome) {
      //       if (isArray(item.calculationData.atHome.totalGrants)) {
      //         return -item.calculationData.atHome.totalGrants[0];
      //       }
      //       else return -item.calculationData.atHome.totalGrants;
      //     } else return item;
      //   })
      // }
      //
      // list = _.map(list, (i) => {
      //   if (!i.amount) i.amount = "$ 0";
      //   if (i.images && i.images.length > 0) {
      //     i.logo = i.images[0];
      //   } else {
      //     i.logo = { url: "https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-icon.png" };
      //   }
      //   return i;
      // });

      return {
        title: type + " Schools",
        list,
      };
    },
    getTotalSchools(tab) {
      return (
        this.getSchoolList(this.app, "In-State", tab).list.length +
        this.getSchoolList(this.app, "Out-of-State", tab).list.length
      );
    },

    clickBtn(item) {
      if (item.title === "Filter") {
        this.isActiveFilter = !this.isActiveFilter;
      } else {
        this.isActiveMyList = true;
      }
    },
    clickTabFilter(event, item) {
      event.preventDefault();
      if (this?.$parent?.$parent?.$parent?.gpaBucketChange)
        this.$parent.$parent.$parent.gpaBucketChange(item);
    },
    selectionChanged(item) {
      if (this?.$parent?.$parent?.$parent?.schoolListSortChange)
        this.$parent.$parent.$parent.schoolListSortChange(item);
    },
  },
};
</script>
<style scoped>
.count-badge {
  position: absolute;
  top: -12px;
  left: 40%;
}
</style>
