<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="() => {open = false; onClose()}">
            <div class="flex items-center justify-center text-center">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                                 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="top-0 sm:top-8 inline-block sm:rounded-md h-fit align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full">
                        <div class="w-full bg-gray-900 text-white flex justify-between h-11 items-center px-2.5">
                            <p>{{title}}</p>
                            <Icon name="XIcon" size="6" isOutline color="white" @click="open = false" />
                        </div>
                        <div class="w-full mt-6" v-for="school in schools">
                            <div class="w-full px-5 border-b flex items-center py-2">
                                <Text color="gray-500" :content="school.type" custom-class="w-2/3" />
                                <Text color="gray-500" :content="school.upfrontLabel" custom-class="w-1/3" />
                            </div>
                            <div v-if="school.list.length > 0" class="w-full px-5 border-b flex items-center py-2" v-for="item in school.list">
                                <Text color="gray-900" :content="item.name" custom-class="w-2/3" />
                                <div class="w-1/3 flex justify-between">
                                    <Text color="primary" :content="`$${item.amount}`" v-if="!item.isLocked" />
                                    <ButtonWithIcon v-else label="Locked" iconName="LockClosedIcon" text-color="gray-500" icon-size="3"
                                            class="w-fit bg-white border border-gray-500 text-xs px-2 py-1" :leading="true" />

                                    <ButtonWithIcon iconName="TrashIcon" text-color="gray-500" icon-size="4"
                                                    class="w-fit bg-white border-0 text-xs px-2 py-1" :leading="true" />
                                </div>
                            </div>
                            <div v-else class="w-full px-5 border-b py-2 flex items-center">
                                <Text color="gray-900" :content="school.emptyLabel" custom-class="w-2/3" />
                            </div>
                        </div>
                        <div class="flex justify-center w-full my-8">
                            <Button :content="buttonLabel" custom-class="bg-primary text-white py-4 px-8" />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import Icon from '../../../atoms/Icons/Icons.vue'
    import Button from '../../../atoms/Button/Button.vue'
    import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue";
    import Text from '../../../atoms/Text/Text.vue'
    import {ref} from "vue";

    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            Icon,
            Button,
            ButtonWithIcon,
            Text
        },
        watch: {
            isOpen: function (val) {
                this.open = ref(val)
            }
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            schools: {
                type: Array,
                default: () => []
            },
            buttonLabel: {
                type: String,
                default: () => ""
            },
            isOpen: {
                type: Boolean,
                default: false,
            },
            onClose: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            const open = ref(this.isOpen)
            return {
                open,
            }
        },
        methods: {
        }
    };
</script>